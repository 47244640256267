import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/vuex';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import swal from 'sweetalert2/dist/sweetalert2.js';
import VueSweetalert2 from 'vue-sweetalert2';
import LoadScript from 'vue-plugin-load-script';
import Print from 'vue-print-nb';
import moment from 'moment';
import VueMask from 'di-vue-mask';
import VueProgressBar from 'vue-progressbar';
import VTooltip from 'v-tooltip';
import qs from 'qs';
import money from 'v-money';
import JsonExcel from "vue-json-excel";
import DateRangePicker from 'vue2-daterange-picker';
import setAuthHeader from './utils/setAuthHeader';
import VueSkeletonLoader from 'skeleton-loader-vue';

//Vue use
Vue.use(Vuelidate);
Vue.use(LoadScript);
Vue.use(Print);
Vue.use(VueMask);
Vue.use(VTooltip);
Vue.use(money, {precision: 4});
Vue.use(DateRangePicker);
Vue.use(VueSkeletonLoader);

Vue.prototype.qs = qs

moment.suppressDeprecationWarnings = true;

//vue sweet alert
window.swal = swal;
const options = {
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', swal.stopTimer)
    toast.addEventListener('mouseleave', swal.resumeTimer)
  }
};
Vue.use(VueSweetalert2, options);
Vue.use(require('vue-moment'));
Vue.use(VueProgressBar, {
  color: 'rgb(0,255,0)',
  failedColor: 'red',
  height: '10px'
})

// Register the component globally
// Vue laravel-vue-pagination
Vue.component('pagination', require('laravel-vue-pagination'));
// Vue JsonExcel
Vue.component("downloadExcel", JsonExcel);
// Skeleton Loader
Vue.component('vue-skeleton-loader', VueSkeletonLoader);

// set base URLs
Vue.prototype.$be_hostname = process.env.VUE_APP_ROOT_HTTP_URL;
Vue.prototype.$be_hostname_secured = process.env.VUE_APP_ROOT_HTTPS_URL;

// select if http or https
if (location.protocol !== "https:") {
  axios.defaults.baseURL = Vue.prototype.$be_hostname;
  Vue.prototype.$profile_picture_baseurl = Vue.prototype.$be_hostname + 'img/profile/'
  Vue.prototype.$client_picture_baseurl = Vue.prototype.$be_hostname + 'img/client/'
} else {
  axios.defaults.baseURL = Vue.prototype.$be_hostname_secured;
  Vue.prototype.$profile_picture_baseurl = Vue.prototype.$be_hostname_secured + 'img/profile/'
  Vue.prototype.$client_picture_baseurl = Vue.prototype.$be_hostname_secured + 'img/client/'
}

// set axios headers globally
if (localStorage.getItem('token')) {
  setAuthHeader(localStorage.getItem('token'));
} else {
  setAuthHeader(false);
}

//filters
Vue.filter('bigcaps', function (value) {
  if (value != null) {
    return value.toUpperCase();
  } else {
    return value;
  }
})
Vue.filter('smallcaps', function (value) {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
})
Vue.filter('allsmallcaps', function (value) {
  return value.toLowerCase();
})
Vue.filter('customdate', function (value) {
	return moment(value).format('MMMM D, YYYY');
})
Vue.filter('capitalize', function (value) {
	return value.charAt(0).toUpperCase() + value.slice(1);
})
Vue.filter('customdate2', function (value) {
	return moment(value).format("MM/DD/YYYY");
})
Vue.filter('truncate', function (text, length, suffix) {
  if (text != null) {
    if (text.length > length) {
        return text.substring(0, length) + suffix;
    } else {
        return text;
    }
  }
});
Vue.filter('trimright', function (text, length) {
  if (text != null) {
    if (text.length > length) {
        return text.substring(0, length);
    } else {
        return text;
    }
  }
});
Vue.filter('trimleft', function (text, length) {
  if (text != null) {
    if (text.length > length) {
        return text.substring(length);
    } else {
        return text;
    }
  }
});
Vue.filter('customdate3', function (value) {
	return moment(value).format("D") + nth(moment(value).format("D")) + ' day ' + moment(value).format("of MMMM YYYY");
})
Vue.filter('customdate4', function (value) {
  return moment(value).format('MMMM D, YYYY 🕒h:mm a');
})
Vue.filter('customdate5', function (value) {
  return moment(value).format('MMMM D, YYYY');
})
Vue.filter('customdate6', function (value) {
  return moment(value).format('MMMM');
})
Vue.filter('customdate7', function (value) {
  return moment(value).format("MMM 'YY");
})
Vue.filter('customtime', function (value) {
  return moment(value).format('h:mm a');
})

function nth(n){return["st","nd","rd"][(((n<0?-n:n)+90)%100-10)%10-1]||"th"}

Vue.filter('toMoney', function (value) {
  Number(value)
  if (typeof value !== "number") {
    value = Number(value);
  }
  var formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  });
  return formatter.format(value);
});

// Number to words filter
var converter = require('number-to-words');

Vue.filter('toWordsPesos', function(value) {
  if (value == 0) {
    return 'zero';
  } else {
    if (!value) return '';
    var xxx = converter.toWords(value);
    return xxx.charAt(0).toUpperCase() + xxx.slice(1);
  }
})

Vue.filter('toWordsCentavos', function(value) {
  if (!value) {
    return ' pesos only';
  }
  else if (value % 1 == 0) {
      return ' pesos only.';
  } else {
    var val = parseFloat(value).toFixed(2);
    return ' pesos and ' + converter.toWords((val.toString().split('.')[1])) + ' centavos only';
  }
})

Vue.filter('moneyToWordsPesos', function(value) {
  var pesos = '';
  var centavos = '';

  if (value == 0) {
    return 'zero';
  } else {
    if (!value) return '';
    var conv = converter.toWords(value);
    pesos =  conv.charAt(0).toUpperCase() + conv.slice(1);
  }

  if (value % 1 == 0) {
    centavos = ' pesos only';
  } else {
    var val = parseFloat(value).toFixed(2);
    centavos = ' pesos and ' + converter.toWords((val.toString().split('.')[1])) + ' centavos only';
  }

  return pesos.concat(centavos);


})

Vue.prototype.$appyear = moment().year()
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
