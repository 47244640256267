<template>
<div class="wrapper">
  <!-- TODO: Add components here -->
  <div class="preloader flex-column justify-content-center align-items-center">
    <img class="animation__shake" :src="this.$client_picture_baseurl + 'logo.png'" alt="Logo" height="60" width="60" style="border-radius: 50%;">
  </div>
  <FloatingButtons />
  <Headernav v-if="isAuth()" />
  <Leftnav v-if="isAuth()" :avatar="avatar" />
  <router-view :callMethod="refreshAvatar" />
  <vue-progress-bar></vue-progress-bar>
  <Footer version="4.0" v-if="isAuth()" />
  
</div>
</template>

<script>

import axios from 'axios';
import Headernav from '@/components/Headernav.vue';
import Leftnav from '@/components/Leftnav.vue';
import Footer from '@/components/Footer.vue';
import FloatingButtons from '@/components/FloatingButtons.vue';

export default {
  name: 'App',

  components: {
    Headernav, Footer, Leftnav, FloatingButtons
  },

  data() {
    return {
      client: {},
      avatar: '',
      isAuthxxx: false,
    }
  },

  methods: { 
    isAuth() {
      if (localStorage.getItem("token") === null) {
        return false;
      } else {
        return true;
      }
    },

    refreshAvatar() {
      this.avatar = localStorage.getItem("avatar")
    }

  },

  mounted() {
      if (localStorage.getItem("theme")) {
        document.body.classList.add('dark-mode')
      }
      this.refreshAvatar();
  }

}

</script>

<style>
@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=swap';
@import './assets/styles/adminlte.min.css';
@import './assets/styles/icheck-bootstrap.min.css';
@import './assets/styles/_main.css';

.login-page, .register-page {
  height: 100vh !important;
  margin-top: 0 !important;
}
.preloader {
    transition: height 0.4s linear !important;
}
</style>
