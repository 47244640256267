<template>
    <div>
        <img width="300" height="300" v-show="cropped" class="img-thumbnail mb-3" :src="croppedImage" alt="" />
        <br/>
        <div v-show="!cropped">
            <vue-avatar 
                :width=300
                :height=300
                :borderRadius=200
                ref="vueavatar"
                @vue-avatar-editor:image-ready="onImageReady"
                :image="photo"
            ></vue-avatar>
            <vue-avatar-scale
                ref="vueavatarscale"
                @vue-avatar-editor-scale:change-scale="onChangeScale"
                :width=300
                :min=1
                :max=3
                :step=0.02
            ></vue-avatar-scale>
            <br/>
            <button class="btn btn-primary mr-2" v-on:click="uploadClicked()"><i class="fa fa-upload" aria-hidden="true"></i> Upload Photo</button>
            <button class="btn btn-primary" v-on:click="cropClicked"><i class="fa fa-crop" aria-hidden="true"></i> Crop Photo</button>
            
        </div>
        <button v-show="cropped" class="btn btn-primary mr-2" v-on:click="resetClicked"><i class="fa fa-undo" aria-hidden="true"></i> Reset</button>
        <button v-show="cropped" :disabled="!croppedImage||!cropped||!form.id" class="btn btn-success" v-on:click="saveClicked"><i class="fa fa-save" aria-hidden="true"></i> Save</button>
    </div>
</template>

<script>

import VueAvatar from '@/components/VueAvatar'
import VueAvatarScale from '@/components/VueAvatarScale'
import Form from 'vform'

export default {
    name: 'UploadImage',

    components: {
        VueAvatar,
        VueAvatarScale
    },

    props:{
        photo: null,
        userid: null,
        callMethod: '',
    },

    data() {
        return {
            rotation: 0,
      scale: 1,
      borderRadius: 200,
            cropped: false,
            croppedImage: null,
            form: new Form({
                id: null,
                photo: null,
            }),
        }
    },

    methods:{
        onChangeScale (scale) {
            this.$refs.vueavatar.changeScale(scale)
        },
        cropClicked(){
            var img = this.$refs.vueavatar.getImageScaled()
            this.croppedImage = img.toDataURL()
            this.form.id = this.userid
            this.form.photo = img.toDataURL()
            this.cropped = true
        },
        resetClicked(){
            //var img = this.$refs.vueavatar.getImageScaled()
            //this.croppedImage = img.toDataURL()
            this.cropped = false
        },
        uploadClicked(){
            document.getElementById('ab-1').click();
        },
        saveClicked(){
            this.$Progress.start();
            this.form.put('api/users/picture/' + this.userid).then(()=>{
                    this.$swal.fire({
                    icon: 'success',
                    title: 'Profile picture updated'
                })
                this.$Progress.finish();
                this.callMethod()
            }).catch(()=>{
                    this.$swal.fire({
                    icon: 'error',
                    title: 'Picture updating failed'
                });
                this.$Progress.fail();
            });
        },
        onImageReady(scale){
            this.$refs.vueavatarscale.setScale(scale)
        },
    }
}
</script>

<style scoped>

</style>