<template>
  <!-- Navbar -->
  <nav v-if="user" class="main-header navbar navbar-expand navbar-dark">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" aria-label="Menu" data-widget="pushmenu" href="#"><i class="fa fa-bars"></i></a>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Messages Dropdown Menu -->
      <!-- Notifications Dropdown Menu -->
      <li class="nav-item">
        <a role="button" v-if="darkmode" class="btn btn-link my-2 my-sm-0 text-warning" type="submit" @click="themeLight"><i class="fa fa-sun-o" aria-hidden="true"></i></a>
        <a role="button" v-else class="btn btn-link my-2 my-sm-0 text-warning" type="submit" @click="themeDark"><i class="fa fa-moon-o" aria-hidden="true"></i></a>
      </li>
      <li class="nav-item">
        <a role="button" class="btn btn-link my-2 my-sm-0 text-violet mr-3" data-widget="fullscreen"><i class="fa fa-arrows-alt" aria-hidden="true"></i></a>
      </li>
      <li class="nav-item">
        <a role="button" class="btn btn-outline-light my-2 my-sm-0" type="submit" @click="handleLogout"><i class="fa fa-sign-out" aria-hidden="true"></i> Logout</a>
      </li>
      <!--
      <li class="nav-item dropdown">
        <a href="#" class="nav-link" data-toggle="dropdown" >
          <img :src="require('@/assets/profile/' + user.photo)" width="30px" height="30px" alt="user image" class="img-circle img-bordered-sm">
          <span class="badge badge-warning navbar-badge">15</span>
        </a>
        
        <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right" 
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 4 new messages
            <span class="float-right text-muted text-sm">3 mins</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 8 friend requests
            <span class="float-right text-muted text-sm">12 hours</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 3 new reports
            <span class="float-right text-muted text-sm">2 days</span>
          </a>
          <div class="dropdown-divider"></div>
          <router-link to="/profile" class="dropdown-item dropdown-footer">
            <b>{{user.name}}</b><br/>
            <small>See your profile</small>
          </router-link>
          <div class="dropdown-divider"></div>
          <a href="javascript:void(0)" class="dropdown-item dropdown-footer bg-danger" @click="handleLogout"><i class="fa fa-sign-out" aria-hidden="true"></i> Logout</a>
        </ul>
        
      </li>
    -->
      
    </ul>
  </nav>
  <!-- /.navbar -->


  
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'Headernav',

  data() {
    return {
      darkmode: null,
    }
  },

  watch: {
    toggleMode(boolean) {
      this.darkmode = boolean;
    }
  },

  methods: {
    handleLogout() {
      localStorage.removeItem('token');
      localStorage.removeItem('avatar');
      localStorage.removeItem('token');
      this.$store.dispatch('user', null);
      localStorage.removeItem('user');
      this.$swal.fire({
          icon: 'info',
          title: 'You have been logged out'
        })
      this.$router.push('/login');
    },

    themeDark() {
      localStorage.setItem('theme', 'dark-mode');
      document.body.classList.add('dark-mode')
      this.darkmode = true;
    },

    themeLight() {
      localStorage.removeItem('theme', 'dark-mode');
      document.body.classList.remove('dark-mode')
      this.darkmode = false;
    },
  },

  computed: {
    ...mapGetters(['user'])
  },

  created() {
    if (!localStorage.getItem("theme")) {
      this.darkmode = false;
    } else {
      this.darkmode = true;
    }
  }

}
</script>